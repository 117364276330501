<template>
 <div class="creationCenter">
  <div class="navBar">
   <div @click="backPage">
    <svg-icon class="navArrow" iconClass="navArrow"/>
   </div>
   <source>
   <div class="navTitle">创作中心</div>
   <!-- <div class="rightBtn"> -->
   <van-popover class="rightBtn" v-model="showPopover" trigger="click" placement="bottom-end">
    <!--        <div class="popoverContent" >-->
    <!--          <div class="popoverItem" @click="jumpImg">图片</div>-->
    <!--          <div class="line"></div>-->
    <!--          <div class="popoverItem" @click="jumpVideo">视频</div>-->
    <!--        </div>-->
    <div class="releaseBtns" @click.stop>
     <div class="releaseBtnGroup">
      <div class="item" @click.stop="jumpImg">
       <img src="@/assets/png/imgIcon.png">
       <span>图片</span>
      </div>
      <div class="division"></div>
      <div class="item" @click.stop="jumpVideo">
       <img src="@/assets/png/videoIcon.png">
       <span>视频</span>
      </div>
     </div>
    </div>
    <template #reference v-if="this.active === 0 || this.active === 1">
<!--     <svg-icon class="publish" iconClass="publish"/>-->
     <div style="color:rgba(102, 102, 102, 1)">发布</div>
    </template>
   </van-popover>
   <!-- </div> -->
   <div v-if="this.active === 2">
    <div @click="callDelPost">清空</div>
   </div>
  </div>
  <div class="main">
   <van-tabs class="creatTab" v-model="active" title-active-color="#333333" title-inactive-color="#999999">
    <van-tab title="已发布">
     <creationCenterItem :status="1"/>
    </van-tab>
    <van-tab title="待审核">
     <creationCenterItem :status="0"/>
    </van-tab>
    <van-tab title="未通过">
     <creationCenterItem ref="del" :status="2"/>
    </van-tab>
   </van-tabs>
  </div>
 </div>
</template>

<script>
import {
 Tab,
 Tabs,
 Toast,
} from 'vant';
import creationCenterItem from "./creationCenterItem"
import {mapGetters} from "vuex";

export default {
 name: "creationCenter",
 components: {
  [Tab.name]: Tab,
  [Tabs.name]: Tabs,
  creationCenterItem
 },
 data() {
  return {
   active: 0,
   showPopover: false,
  }
 }, computed: {
  ...mapGetters({
   userInfo: 'userInfo',
   isVip: 'isVip'
  }),
 },
 methods: {
  // 删除
  callDelPost() {
   // this.$refs.del.callDelPost();
   this.$bus.$emit('callDelPost');
  },
  // 返回
  backPage() {
   this.$router.go(-1);
  },
  jumpImg() {
   this.$router.push("/publishImg");
  //  if (!this.userInfo.isVip) {
  //   this.$router.push("/publishImg")
  //  } else {
  //   this.$bus.$emit("vipPopup", {
  //    // id: "",
  //    state: 1,
  //    // goldenNum: this.postInfo.coins,
  //    closeBtn: () => {
  //     this.$bus.$emit("closeVipPopup");
  //    }
  //   });
  //   this.showPopover = false;
  //  }
  },
  jumpVideo() {
    this.$router.push("/publishVideo");
  //  if (!this.userInfo.isVip) {
  //   this.$router.push("/publishVideo")
  //  } else {
  //   this.$bus.$emit("vipPopup", {
  //    // id: "",
  //    state: 1,
  //    // goldenNum: this.postInfo.coins,
  //    closeBtn: () => {
  //     this.$bus.$emit("closeVipPopup");
  //    }
  //   });
  //   this.showPopover = false;
  //  }
  }
 }
}
</script>

<style lang="scss" scoped>
.releaseBtns {
 display: flex;
 justify-content: flex-end;

 .releaseBtnGroup {
  //width: 121px;
  //height: 41px;
  background: linear-gradient(180deg, #FFFCE0 0%, #FFF 30%);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 10px 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  font-size: 15px;
  color: rgb(255, 255, 255);

  .item {
   display: flex;
   align-items: center;
   color: #FF8F4C;
   font-weight: bold;
   font-size: 14px;

   img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
   }
  }

  .division {
   width: 84px;
   height: 1px;
   background: #FF8F4C;
   margin: 6px 0;
  }
 }
}

.popoverContent {
 width: 121px;
 height: 41px;
 font-size: 15px;
 display: flex;
 align-items: center;
 justify-content: center;
 background: #94d6da;
 color: #fff;

 .line {
  width: 1px;
  height: 33px;
  background: #fff;
 }

 .popoverItem {
  width: 60px;
  text-align: center;
 }
}

.creationCenter {
 height: 100%;
//  background: #F5F5F5;

 .navBar {
  height: 44px;
  padding: 0 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  //border-bottom: 1px solid #e6e6e6;

  .navArrow {
   width: 17px;
   height: 17px;
  }

  .navTitle {
   flex: 2;
   text-align: center;
   font-size: 16px;
   color: #333333;
  }

  .rightBtn {
   .publish {
    width: 18px;
    height: 19px;
   }
  }
 }


 .main {
  height: 100%;
  // padding: 0 12px;
  box-sizing: border-box;
 }

 .creatTab {

  /deep/ .van-tabs__wrap {
   height: 44px;
   //margin-bottom: 12px;
   display: flex;
   justify-content: center;
   background: #FFFFFF;
  }

  /deep/ .van-tabs__nav {
   display: flex;
   justify-content: center;
   margin-left: 12px;
  }

  /deep/ .van-tab {
   // width: 54px;
   flex: none;
   margin: 0 16px;
  }

  /deep/ .van-tab__text {
   font-size: 14px;
   font-weight: 400;
  }

  /deep/ .van-tab--active {
   font-weight: 500;
  }

  /deep/ .van-tabs__line {
   width: 18px;
   height: 2px;
   background: #FBD13D;
   bottom: 22px;
  }
 }
}
</style>
